import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showSuccess, showError } from '../../../utils/Notifications';
import { resetPointAction, getPointAction, updatePointAction, getUsersAction, addPointAction, getDeliveryFilterAction, getFormFilterAction, getLocationFilterAction, getDrawingsAction, getDeliveryAction, deletePointsAction, addDeliveryPointCommentAction, deleteDeliveryPointCommentsAction, getPointDeliveriesAction } from '../../../actions/deliverActions';
import DeliverSideMenu from './DeliverSideMenu';
import { CloseCircleOutlined, DownOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
    Row,
    Col,
    Button,
    Input,
    Modal,
    Select,
    Dropdown,
    Menu,
    DatePicker,
    Tag,
    Tooltip,
    Timeline,
    Upload,
    Drawer,
} from 'antd';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import './deliver.css';
import { HaveAbility } from '../../../utils/RolesAndAbilities';
import DeliverPointChild from './DeliverPointChildModal';

const { Option } = Select;
const { TextArea } = Input;

const RenderHTML = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class DeliverPointModal extends Component
{
    map = null;

    image_overlay = null;

    marker = null;

    state = {
        can_write: false,
        can_delete: false,
        selectedRowKeysComments: [],
        selectedRowKeysPoints: [],

        leaflet_initialized: false,
        show_drawing_modal: false,

        show_photo_modal: false,
        photo_url: false,

        tags: [],
        inputVisible: false,
        inputValue: '',

        errors: [],
        can_write: false,

        comment: '',

        drawing_url: '',
        drawing_id: false,
        drawing_marker_position: false,
        // drawing_marker_x_y: false,
        drawing_bounds: [[0, 0], [0, 0]],

        status: false,

        photos: [],
        fileList: [],
        delete_photos: [],

        point_id: false,
        point_parent_id: false,

        sub_point_modal: false,

        point: {},
        comments: [],
        log: [],

        something_changed: false,
        something_changed_sub: false,

        comment: '',
        comment_id: false,

        readonly: false,
        map_locked: true,

        loading: true
    };

    componentDidMount()
    {
        this.reset();

        const { childRef } = this.props;

        // childRef(this); // ?

        // this.props.setHandleSave(this.handleSave);

        // this.props.getPointDeliveriesAction(this.props.project_id);

        this.setState({
            can_write: this.props.HaveAbility('deliver', 'write'),
            can_delete: this.props.HaveAbility('deliver', 'delete'),
            something_changed: false,
            something_changed_sub: false,
            readonly: this.props.readonly === true ? true : false
        });

        //
        this.setState({
            point_id: (this.props.sub_point_id ? this.props.sub_point_id : this.props.point_id),
            point_parent_id: (this.props.sub_point_parent_id ? this.props.sub_point_parent_id : this.props.point_parent_id)
        }, () => {

            if(this.props.leaflet)
            {
                this.includeLeaflet();
            }
            else
            {
                this.loadData(this.state.point_id, this.state.point_parent_id);
            }
        });
    }

    reset()
    {
        this.setState({

            tags: [],
            photos: [],
            something_changed: false,
            something_changed_sub: false
        });

        this.props.resetPointAction();
    }

    loadData = (point_id = false, point_parent_id = false) =>
    {
        if(!isNaN(point_id) && point_id >= 1)
        {
            this.props.getPointAction(point_id).then(() => {
                this.setState({
                    something_changed: false,
                    something_changed_sub: false,
                    point: this.props.point,
                    comments: this.props.comments,
                    log: this.props.log,

                    status: this.props.point.status,
                    tags: this.props.point.tags ? this.props.point.tags.split(',') : [],
                    drawing_url: this.props.point.drawing && this.props.point.drawing.drawing_url ? this.props.point.drawing.drawing_url : false,
                    drawing_id: this.props.point.drawing && this.props.point.drawing.id ? this.props.point.drawing.id : false,
                    drawing_marker_position: this.props.point.position ? JSON.parse(this.props.point.position) : false,
                    // drawing_marker_x_y: this.props.point.x_y ? JSON.parse(this.props.point.x_y) : false,
                    original_drawing_marker_position: this.props.point.position ? JSON.parse(this.props.point.position) : false,
                    // original_drawing_marker_x_y: this.props.point.x_y ? JSON.parse(this.props.point.x_y) : false,
                    photos: this.props.point.photos ? this.props.point.photos : [],
                    photo_data: this.props.point.photo_data ? JSON.parse(this.props.point.photo_data) : [],
                    loading: false
                }, () => {

                    if(this.props.leaflet && !this.state.point.parent_id)
                    {
                        this.initLeaflet();
                    }
                });
            });
        }
        else
        {
            this.setState({ loading: false });
        }

        // this.props.getPointDeliveriesAction(this.props.project_id);

        this.props.getUsersAction(this.props.project_id, false, 'contractor');

        this.props.getDrawingsAction(this.props.project_id);
    }

    includeLeaflet()
    {
        var this_obj = this;

        // leaflet
        // - css
        var style = document.createElement("link");
        style.rel = 'stylesheet';
        style.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';

        document.head.appendChild(style);

        // - js
        var script = document.createElement("script");
        script.async = true;
        script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
        script.onload = function()
        {
            this_obj.loadData(this_obj.state.point_id, this_obj.state.point_parent_id);
        }

        document.head.appendChild(script);
    }

    initLeaflet()
    {
        // this.setState({ show_drawing_modal: true });

        if(this.state.leaflet_initialized)
        {
            // this.setState({ show_drawing_modal: false });

            return true;
        }

        this.setState({ leaflet_initialized: true }, () => {

            var center = [0, 0];

            // this.setState({ show_drawing_modal: true });

            // map
            this.map = window.L.map('map', {
                center: center,
                minZoom: 0,
                zoom: 0,
                attributionControl: false,
                // crs: window.L.CRS.Simple
            });

            // leaflet
            // this.map.fitWorld();
            // this.map.setView([0, 0], 0);
            // this.map.invalidateSize();

            // this.setState({ show_drawing_modal: false });

            var this_obj = this;

            // image
            var imageUrl = this.state.drawing_url;

            var img = new Image();
            var bounds = [[0, 0], [0, 0]];

            // click
            var disable_map_click = 0;

            img.onload = function()
            {
                var height = img.height;
                var width = img.width;



                var transformed_height = height;
                var transformed_width = width;

                if(height > (width / 2))
                {
                    transformed_width = 180 * width / height;
                    transformed_height = 180;
                }
                else
                {
                    transformed_height = 180 * height / width;
                    transformed_width = 90;
                }

                // image bounds
                var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                // add image to map
                this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                this_obj.image_overlay.bringToFront();

                // image bounds
                bounds = window.L.latLngBounds([imageBounds, center]);
                // this_obj.map.setMaxBounds(bounds);
                this_obj.map.setMaxBounds(imageBounds);

                //
                window.L.tileLayer('').addTo(this_obj.map); // ?

                // marker
                var position = this_obj.state.drawing_marker_position;

                if(position)
                {
                    var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
                        orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
                        greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

                    var icon = redIcon;

                    if(this_obj.state.status == 'submitted'){ icon = orangeIcon; }
                    else if(this_obj.state.status == 'completed'){ icon = greenIcon; }

                    this_obj.marker = window.L.marker(new window.L.LatLng(position.lat, position.lng), {icon: icon}).addTo(this_obj.map);
                }
            }

            img.src = imageUrl;

            //
            this.map.on('click', onMapClick);

            // on map click, add point
            function onMapClick(event)
            {
                if(!this_obj.state.map_locked)
                {
                    var position = event.latlng;
                    // var zoom = this_obj.map.getZoom();

                    // console.log('event', event);

                    // var x_y = event.layerPoint;
                    // var x_y = this_obj.map.latLngToLayerPoint(position);
                    // var x_y = this_obj.map.latLngToContainerPoint(position);

                    // console.log('x_y', x_y, 'zoom', zoom);

                    // translate x_y based on zoom level
                    // var zoom = this_obj.map.getZoom();

                    // console.log('zoom', zoom);

                    // x_y.x = x_y.x / (zoom + 1);
                    // x_y.y = x_y.y / (zoom + 1);

                    // console.log('translated x_y', x_y, 'zoom', zoom);

                    if(this_obj.marker)
                    {   // move marker
                        // var position = event.latlng;

                        // this_obj.setState({ drawing_marker_position: position });
                        this_obj.setState({
                            drawing_marker_position: position,
                            // drawing_marker_x_y: this_obj.map.layerPointToContainerPoint(this_obj.map.latLngToLayerPoint(event.latlng))
                            // drawing_marker_x_y: x_y // this_obj.map.latLngToLayerPoint(event.latlng)
                        });

                        if(position)
                        {
                            this_obj.marker.setLatLng(position);
                        }
                    }
                    else
                    {   // add marker
                        // var position = event.latlng;

                        // this_obj.setState({ drawing_marker_position: position });
                        this_obj.setState({
                            drawing_marker_position: position,
                            // drawing_marker_x_y: this_obj.map.layerPointToContainerPoint(this_obj.map.latLngToLayerPoint(event.latlng))
                            // drawing_marker_x_y: x_y // this_obj.map.latLngToLayerPoint(event.latlng)
                        });

                        if(position)
                        {
                            var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
                                orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
                                greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

                            var icon = redIcon;

                            if(this_obj.state.status == 'submitted'){ icon = orangeIcon; }
                            else if(this_obj.state.status == 'completed'){ icon = greenIcon; }

                            this_obj.marker = window.L.marker(new window.L.LatLng(position.lat, position.lng), {icon: icon}).addTo(this_obj.map);
                        }
                    }
                }
            };

            // drag handler
            this.map.on('drag', function(){
                this_obj.map.panInsideBounds(bounds, {animate: true});
            });
        });
    }

    changeStatus(status)
    {
        var redIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f00;" />'}),
            orangeIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #f80;" />'}),
            greenIcon = window.L.divIcon({iconSize: [32, 32], iconAnchor: [16, 16], className: 'cobee-icon', html: '<span style="display: block; position: relative; width: 32px; height: 32px; border-radius: 16px; background-color: #0f0;" />'});

        var icon = redIcon;

        if(status == 'submitted'){ icon = orangeIcon; }
        else if(status == 'completed'){ icon = greenIcon; }

        if(this.marker)
        {
            this.marker.setIcon(icon);
        }
    }

    changeDrawing(id)
    {
        // - remove marker
        if(this.marker)
        {
            this.marker.removeFrom(this.map);

            this.marker = null;
        }

        // - remove current image overlay
        this.map.removeLayer(this.image_overlay);

        // - add new image overlay
        var this_obj = this;

        var imageUrl = null;

        this.props.drawings.map((drawing) => {

            if(drawing.id == id)
            {
                imageUrl = drawing.drawing_url;
            }
        });

        //
        this.setState({ drawing_url: imageUrl });

        //
        //if(imageUrl)
        {
            var img = new Image();
            var bounds = [[0, 0], [0, 0]];

            // click
            var disable_map_click = 0;

            img.onload = function()
            {
                var height = img.height;
                var width = img.width;

                var transformed_height = height;
                var transformed_width = width;

                if(height > (width / 2))
                {
                    transformed_width = 180 * width / height;
                    transformed_height = 180;
                }
                else
                {
                    transformed_height = 180 * height / width;
                    transformed_width = 360;
                }

                // image bounds
                var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                // add image to map
                this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                // this_obj.image_overlay.bringToFront();
                this_obj.image_overlay.bringToBack();

                // image bounds
                bounds = window.L.latLngBounds([imageBounds, [0, 0]]);
                this_obj.map.setMaxBounds(bounds);

                // reinit map
                this_obj.map.fitWorld();
                this_obj.map.setView([0, 0], 0);
                this_obj.map.invalidateSize();
            }

            img.src = imageUrl;
        }
    }

    handleSave(redirect = true, child = false)
    {
        // console.log('handle save (child)');

        this.props.form.validateFields((errors, values) => {

            if(errors)
            {
                delete errors.user_id; // not used
            }

            this.setState({ errors: errors });

            if(!errors || !Object.keys(errors).length)
            {   // ok

                if(this.state.point_parent_id)
                {
                    values.parent_id = this.state.point_parent_id;
                }

                if(values.due_date)
                {
                    values.due_date = values.due_date.format('YYYY-MM-DD');
                }

                values.tags = this.state.tags.join(',');

                values.position = JSON.stringify(this.state.drawing_marker_position);
                // values.x_y = JSON.stringify(this.state.drawing_marker_x_y);

                values.delete_photos = JSON.stringify(this.state.delete_photos);

                // console.log('point_id', this.state.point_id, values, redirect);

                // console.log('redirect', redirect, 'point ref', this.point_ref, 'parent', this.point_ref ? this.point_ref.props.subpoint : false);

                if(isNaN(this.state.point_id) || this.state.point_id < 1)
                {   // add point
                    this.props.addPointAction(this.props.project_id, values, this.state.fileList).then(() => {

                        // const value = this.props.form.getFieldValue('comment');

                        // this.props.form.setFieldsValue({ ['comment']: '' });

                        this.setState({ fileList: [], something_changed: false }, () => {

                            showSuccess('Succesvol toegevoegd');

                            if(redirect)
                            {
                                // this.close(true, (this.state.point_parent_id ? true : false), true);
                                // this.close(true, (this.point_ref && this.point_ref.props.subpoint ? true : false), true);
                                this.close(true, child, true);
                            }
                            else
                            {
                                this.loadData(this.props.form.id, this.props.form.parent_id);
                            }
                        });
                    });
                }
                else
                {   // update point
                    this.props.updatePointAction(this.state.point_id, values, this.state.fileList).then(() => {

                        // const value = this.props.form.getFieldValue('comment');

                        // this.props.form.setFieldsValue({ ['comment']: '' });

                        this.setState({ fileList: [], something_changed: false }, () => {

                            showSuccess('Succesvol opgeslagen');


                            if(redirect)
                            {
                                // if(this.props.subpoint)
                                // {
                                //     this.closeSubPoint(true, true);
                                // }
                                // else
                                // {
                                //     this.props.closePoint(true, true);
                                // }

                                // this.close(true, (this.state.point_parent_id ? true : false), true);
                                // this.close(true, (this.point_ref && this.point_ref.props.subpoint ? true : false), true);
                                // this.close(true, this.props.subpoint, true);
                                this.close(true, child, true);
                            }
                            else
                            {
                                this.loadData(this.state.point_id, this.state.point_parent_id);
                            }
                        });
                    });
                }/**/
            }
            else
            {
                showError('Niet alle velden ingevuld');
            }
        });
    }

    getStatusText(status)
    {
        if(status == 'submitted'){ return 'Controleren'; }
        else if(status == 'completed'){ return 'Afgehandeld'; }
        else { return 'Open'; }
    }

    showPhoto(url, index = false, id = false)
    {
        this.setState({
            show_photo_modal: true,
            photo_url: url,
            photo_index: index,
            photo_id: id
        });
    }

    handleClose = removedTag => {

        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
    };

    showInput = () => {

        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {

        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {

        let tags = this.state.tags;

        tags = [...tags, this.state.inputValue];

        this.setState({
            tags: tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    saveInputRef = input => (this.input = input);

    //
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {

      if(!file.url && !file.preview){

        file.preview = await getBase64(file.originFileObj);
      }

      this.setState({

        previewImage: file.url || file.preview,
        previewVisible: true,
      });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    selectDelivery(id)
    {
        // determine drawing
        this.props.getDeliveryAction(id).then(() => {

            this.setState({
                drawing_url: this.props.delivery.drawing_url,
                delivery_id: id
            }, () => {

                if(!this.state.leaflet_initialized)
                {
                    this.initLeaflet();
                }
                else
                {
                    // - remove marker
                    if(this.marker)
                    {
                        this.marker.removeFrom(this.map);

                        this.marker = null;
                    }

                    // - remove current image overlay
                    this.map.removeLayer(this.image_overlay);

                    // - add new image overlay
                    var this_obj = this;

                    var imageUrl = this.state.drawing_url

                    //
                    var img = new Image();
                    var bounds = [[0, 0], [0, 0]];

                    // click
                    var disable_map_click = 0;

                    img.onload = function()
                    {
                        var height = img.height;
                        var width = img.width;

                        var transformed_height = height;
                        var transformed_width = width;

                        if(height > (width / 2))
                        {
                            transformed_width = 180 * width / height;
                            transformed_height = 180;
                        }
                        else
                        {
                            transformed_height = 180 * height / width;
                            transformed_width = 360;
                        }

                        // image bounds
                        var imageBounds = [[(-transformed_height / 2), (-transformed_width / 1)], [(transformed_height / 2), (transformed_width / 1)]];

                        // add image to map
                        this_obj.image_overlay = window.L.imageOverlay(imageUrl, imageBounds).addTo(this_obj.map);
                        // window.L.imageOverlay(imageUrl, imageBounds).bringToFront();
                        // this_obj.image_overlay.bringToFront();
                        this_obj.image_overlay.bringToBack();

                        // image bounds
                        bounds = window.L.latLngBounds([imageBounds, [0, 0]]);
                        this_obj.map.setMaxBounds(bounds);

                        // reinit map
                        this_obj.map.fitWorld();
                        this_obj.map.setView([0, 0], 0);
                        this_obj.map.invalidateSize();
                    }

                    img.src = imageUrl;
                }
            });
        });
    }

    selectDrawing(id)
    {
        this.setState({ drawing_id: id });

        this.changeDrawing(id);
    }

    showDrawing()
    {
        this.map.invalidateSize();

        this.setState({ show_drawing_modal: true }, () => {

            var this_obj = this;

            // fix for centering map (AntD v5)
            setTimeout(function()
            {
                this_obj.map.fitWorld();
                this_obj.map.setView([0, 0], 0);
                this_obj.map.invalidateSize();
            }, 100);
        });
    }

    getStatusColor(status)
    {
        // if(status == 'new'){ return '#888888'; }
        if(status == 'submitted'){ return '#ff8800'; }
        else if(status == 'completed'){ return '#00ff00'; }
        else { return '#ff0000'; }
    }

    addNewSubPoint(parent_id)
    {
        this.setState({
            sub_point_modal: true,
            sub_point_id: 'add', // sub_point.id,
            sub_point_parent_id: parent_id,
            modal_title: 'Sub opleverpunt toevoegen'
        });
    }

    close(refresh = false, subpoint = false, force = false)
    {
        console.log('close', refresh, subpoint, this.state.point_id, this.state.point_parent_id);

        // if(subpoint || this.props.subpoint)
        //if(this.props.closeSubPoint)
        if(subpoint)
        {
            // this.setState({ sub_point_modal: false }, () => {
            //     this.loadData(this.state.point_id, this.state.point_parent_id);
            // });

            // this.loadData(this.state.point_id, this.state.point_parent_id);
            // this.loadData(this.state.point_parent_id);

            this.closeSubPoint(false, true);
        }
        else
        {
            if(!this.state.something_changed || force)
            {
                this.props.closePoint(true, true);

                // this.setState({
                //     point_modal: false,
                //     something_changed: false
                // });
                //
                // if(refresh)
                // {
                //     this.loadData();
                // }
            }
            else
            {
                Modal.confirm({
                    title: 'Niet opgeslagen wijzigingen',
                    content: 'Klik op JA om door te gaan en de wijzigingen alsnog op te slaan.',
                    okText: 'Ja',
                    okType: 'danger',
                    cancelText: 'Nee',
                    onOk: () => {
                        return new Promise((resolve, reject) => {

                            this.handleSave();

                            resolve();

                        }).catch(() => {});
                    },
                    onCancel: () => {

                        this.closeSubPoint(true, true);
                    },
                });
            }

            // this.props.closePoint(refresh, force);
        }
    }

    closeComment = (refresh = true) =>
    {
        this.setState({ comment_modal: false, comment_id: false, comment: '' });

        if(refresh)
        {
            this.loadData(this.state.point_id, this.state.point_parent_id);
        }
    }

    closePoint = (refresh = false, force = false) =>
    {
        // console.log('closeSubPoint', refresh, force);

        if(!this.state.something_changed || force)
        {
            // this.setState({ sub_point_modal: false });
            //
            // this.loadData(this.props.point_id, this.props.point_parent_id);

            this.props.closePoint();
        }
        else
        {
            Modal.confirm({
                title: 'Niet opgeslagen wijzigingen punt',
                content: 'Klik op JA om door te gaan en de wijzigingen alsnog op te slaan.',
                okText: 'Ja',
                okType: 'danger',
                cancelText: 'Nee',
                onOk: () => {
                    return new Promise((resolve, reject) => {

                        this.handleSave();

                        resolve();

                        // this.setState({ sub_point_modal: false });
                        //
                        // this.loadData(this.props.point_id, this.props.point_parent_id);

                    }).catch(() => {});
                },
                onCancel() {},
            });
        }
    }

    closeSubPoint = (refresh = false, force = false) =>
    {
        console.log('closeSubPoint', refresh, force);

        if(!this.state.something_changed_sub || force)
        {
            this.setState({ sub_point_modal: false });

            if(!this.state.something_changed_sub)
            {
                this.loadData(this.props.point_id, this.props.point_parent_id);
            }
            else if(refresh && this.state.something_changed_sub)
            {
                Modal.confirm({
                    title: 'Niet opgeslagen wijzigingen punt',
                    content: 'Klik op JA om door te gaan en de wijzigingen alsnog op te slaan.',
                    okText: 'Ja',
                    okType: 'danger',
                    cancelText: 'Nee',
                    onOk: () => {
                        return new Promise((resolve, reject) => {

                            this.handleSave(false);

                            resolve();

                        }).catch(() => {});
                    },
                    onCancel() {},
                });
            }
        }
        else
        {
            Modal.confirm({
                title: 'Niet opgeslagen wijzigingen sub punt',
                content: 'Klik op JA om door te gaan en de wijzigingen alsnog op te slaan.',
                okText: 'Ja',
                okType: 'danger',
                cancelText: 'Nee',
                onOk: () => {
                    return new Promise((resolve, reject) => {

                        this.point_ref.handleSave();

                        resolve();

                        // this.setState({ sub_point_modal: false });
                        //
                        // this.loadData(this.props.point_id, this.props.point_parent_id);

                    }).catch(() => {});
                },
                onCancel() {},
            });
        }
    }

    deletePhoto(photo, index)
    {

        Modal.confirm({
          title: 'Wil je de geselecteerde items archiveren?',
          content: 'Als je op JA klikt, worden alle geselecteerde punten gearchiveerd.',
          okText: 'Ja',
          okType: 'danger',
          cancelText: 'Nee',
          onOk: () => {
            return new Promise((resolve, reject) => {

                var photos = [];
                var delete_photos = this.state.delete_photos;

                this.state.photos.map((record, i) => {
                    if(i != index)
                    {
                        photos.push(record);
                    }
                    else
                    {
                        delete_photos.push(photo);
                    }
                });

                this.setState({
                    photos: photos,
                    delete_photos: delete_photos
                });

                resolve();

            }).catch((e) => { console.log('Error archive points', e) });
          },
          onCancel() {},
        });
    }

    showComment(comment)
    {
        if(comment)
        {
            this.setState({
                comment_modal: true,
                comment_id: comment.id,
                comment: comment.comment
            });
        }
        else
        {
            this.setState({
                comment_modal: true,
                comment_id: false,
                comment: ''
            });
        }
    }

    addComment(point_id)
    {
        this.props.form.validateFields((errors, values) => {

            if(!errors)
            {
                this.props.addDeliveryPointCommentAction(point_id, values).then(() => {

                    this.closeComment();
                });
            }
        });
    }

    deleteComments(point_id)
    {
        Modal.confirm({
            title: 'Opmerkingen verwijderen',
            content: 'Klik op JA om door te gaan.',
            okText: 'Ja',
            okType: 'danger',
            cancelText: 'Nee',
            onOk: () => {
                return new Promise((resolve, reject) => {

                    this.props.deleteDeliveryPointCommentsAction(point_id, this.state.selectedRowKeysComments).then(() => {

                        resolve();

                        this.loadData(this.state.point_id, this.state.point_parent_id);
                    });

                }).catch(() => {});
            },
            onCancel() {},
        });
    }

    deletePoints(point_id)
    {
        Modal.confirm({
            title: 'Sub punten verwijderen',
            content: 'Klik op JA om door te gaan.',
            okText: 'Ja',
            okType: 'danger',
            cancelText: 'Nee',
            onOk: () => {
                return new Promise((resolve, reject) => {

                    this.props.deletePointsAction(this.props.project_id, this.state.selectedRowKeysPoints).then(() => {

                        resolve();

                        this.loadData(this.state.point_id, this.state.point_parent_id);
                    });

                }).catch(() => {});
            },
            onCancel() {},
        });
    }

    showSubPoint(sub_point)
    {
        this.setState({
            sub_point_modal: true,
            sub_point_id: sub_point.id,
            sub_point_parent_id: sub_point.parent_id,
            modal_title: sub_point.subject
        });
    }

    somethingChanged = () =>
    {
        this.setState({ something_changed: true });

        this.props.somethingChanged();
    }

    somethingChangedSub = (changed = true) =>
    {
        this.setState({ something_changed_sub: changed });
    }

    onSelectChangeComments = (selectedRowKeysComments) => {

        this.setState({ selectedRowKeysComments });
    }

    onSelectChangePoints = (selectedRowKeysPoints) => {

        this.setState({ selectedRowKeysPoints });
    }

    //
    render()
    {
        const { getFieldDecorator } = this.props.form;

        const { tags, inputVisible, inputValue } = this.state;

        const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        );

        //
        const comment_columns = [
            {
              title: 'Opmerking',
              dataIndex: 'comment',
              render: (text, record) => (
                <span>
                    <Link
                        onClick={() => {
                            this.showComment(record);
                        }}
                        >
                        {record.comment ? (record.comment.length > 50 ? record.comment.substring(0, 50)+'...' : record.comment) : ''}
                    </Link>
                </span>
              )
            },
            {
                title: 'Geplaatst door',
                dataIndex: 'from_user_email_address',
                render: (text, record) => (
                    <span>
                        {text}
                    </span>
                )
            },
            {
                title: 'Geplaatst op',
                dataIndex: 'created_at',
                render: (text, record) => (
                    <span>
                        { moment(text).format('DD/MM/YYYY') }
                    </span>
                )
            }
        ];

        //
        const point_columns = [
            {
              title: 'Status',
              dataIndex: 'status',
              // sorter: (a, b) => { return a.status.localeCompare(b.status)},
              render: (text, record) => (
                <div style={{height: 18, width: 18, borderRadius: 18, backgroundColor: this.getStatusColor(text)}}></div>
              ),
              // filters: [
              //   {
              //     text: 'Open',
              //     value: 'open',
              //   },
              //   {
              //     text: 'Controleren',
              //     value: 'submitted',
              //   },
              //   {
              //     text: 'Afgehandeld',
              //     value: 'completed',
              //   }
              // ],
              // onFilter: (value, record) => record.status === value,
              width: 110
            },
            {
                title: 'Onderwerp',
                dataIndex: 'subject',
                render: (text, record) => (
                    <span>
                        <Link
                            onClick={() => {
                                // this.reset();
                                // this.loadData(record.id, record.parent_id);
                                this.showSubPoint(record);
                            }}
                            >
                            {text}
                        </Link>
                    </span>
                )
            },
            {
                title: 'Verantwoordelijke',
                dataIndex: 'user_name',
                // sorter: (a, b) => { return a.user_name.localeCompare(b.user_name)},
                render: (text, record) => (
                    <span>
                        {text}
                    </span>
                ),
                // filters: this.getUserFilters(),
                // onFilter: (value, record) => record.assigned_to_delivery_user_id === value,
            },
            {
                title: 'Uiterlijk gereed',
                dataIndex: 'due_date',
                render: (text, record) => (
                    <span style={{color: (record.filter !== 'expired' ? '#000000' : '#ff0000')}}>
                        {record.expired ? 'Verlopen' : (text ? moment(text).format('DD/MM/YYYY') : 'Niet opgegeven')}
                    </span>
                ),
                // filters: [
                //   {
                //     text: 'Verlopen',
                //     value: 'expired',
                //   },
                //   {
                //     text: 'Niet verlopen',
                //     value: 'not-expired',
                //   },
                //   {
                //     text: 'Niet ingesteld',
                //     value: 'not-set',
                //   },
                // ],
                // onFilter: (value, record) => record.filter === value,
            }
        ];

        const { selectedRowKeysComments, selectedRowKeysPoints } = this.state;

        const rowSelectionComments = {
            selectedRowKeysComments,
            onChange: this.onSelectChangeComments,
            hideDefaultSelections: true,
        };

        const rowSelectionPoints = {
            selectedRowKeysPoints,
            onChange: this.onSelectChangePoints,
            hideDefaultSelections: true,
        };

        return (
            <div>
                <Form
                    id={(this.state.point_parent_id ? 'sub_' : '') + 'deliver_point'}
                    onSubmit={(event) => { event.preventDefault(); this.handleSave((!isNaN(this.state.point_id) || this.props.subpoint ? true : false)); }}
                    style={{ width: '100%', minWidth: '660px' }}
                    layout={'vertical'}
                    >

                    {/*<Row gutter={24} className="mbm">
                        <Col span={12}>
                            <div>
                                <Button disabled={!this.state.can_write} type={'new'} onClick={() => { this.handleSave((!isNaN(this.state.point_id) ? true : false)); }}>Opslaan</Button>
                                <Link onClick={() => { this.close(); }}>
                                    <Button>Sluiten</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>*/}

                    { !this.state.point_id && !this.state.point_parent_id ?
                    <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                        <Col className="gutter-row" span={24}>

                            <Form.Item
                                label="Vooroplevering"
                                hasFeedback
                                className="mbn"
                                >
                                {getFieldDecorator('delivery_id', {
                                    rules: [{ required: false, message: true }],
                                    initialValue: this.props.point.delivery_id,
                                    onChange: (id) => { this.somethingChanged(); this.selectDelivery(id); }
                                })(
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Selecteer een vooroplevering"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    { this.props.points_deliveries.map((record, index) => {
                                        if(record.children && record.children.length)
                                        {
                                            return record.children.map((child, index) => {
                                                return <Option value={child.id}>{child.parent_description + ' - ' + child.address_label}</Option>
                                            });
                                        }
                                        else
                                        {
                                            return <Option value={record.id}>{record.description}</Option>
                                        }
                                    }) }
                                </Select>
                                )}
                            </Form.Item>

                        </Col>
                    </Row> : null }

                    { !this.state.point_id && !this.state.delivery_id && !this.state.point_parent_id ? null :
                    <div>

                        { /*!this.props.point.parent_id && !this.state.point_parent_id ?
                        <Row>
                            <Col span={24}>

                                <Form.Item
                                    label="Tekening"
                                    hasFeedback
                                    >
                                    <div style={{ marginBottom: 6 }}>
                                        <div style={{ width: 85, height: 85, float: 'left', position: 'relative', marginRight: 8, marginBottom: 8 }}>
                                            <div
                                                onClick={() => { this.showDrawing(); }}
                                                style={{
                                                    backgroundImage: 'url('+this.state.drawing_url+')',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center, center',
                                                    backgroundRepeat: 'no-repeat',
                                                    cursor: 'pointer',
                                                    width: 85,
                                                    height: 85,
                                                    float: 'left',
                                                    position: 'relative',
                                                    marginRight: 6,
                                                    marginBottom: 6,
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#e0e0e0',
                                                    borderRadius: 5
                                                }}
                                                ></div>
                                        </div>
                                    </div>
                                </Form.Item>

                            </Col>
                        </Row> : null*/ }

                        <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={24} style={{columnGap: '20px', rowGap: '20px'}}>

                                { !this.state.point.parent_id && !this.state.point_parent_id ?
                                <Form.Item
                                    label="Tekening"
                                    hasFeedback
                                    className="mbn"
                                    style={{ display: 'inline', float: 'left' }}
                                    >
                                    <div style={{ marginBottom: 6 }}>
                                        <div style={{ width: 85, height: 85, float: 'left', position: 'relative', marginRight: 8, marginBottom: 8 }}>
                                            <div
                                                onClick={() => { this.somethingChanged(); this.showDrawing(); }}
                                                style={{
                                                    backgroundImage: 'url('+this.state.drawing_url+')',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center, center',
                                                    backgroundRepeat: 'no-repeat',
                                                    cursor: 'pointer',
                                                    width: 85,
                                                    height: 85,
                                                    float: 'left',
                                                    position: 'relative',
                                                    marginRight: 6,
                                                    marginBottom: 6,
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#e0e0e0',
                                                    borderRadius: 5
                                                }}
                                                ></div>
                                        </div>
                                    </div>
                                </Form.Item> : null }

                                <Form.Item
                                    label="Foto(s)"
                                    hasFeedback
                                    style={{display: 'inline', width: 'auto', float: 'left'}}
                                    className="mbn"
                                    >

                                    <div style={{ marginBottom: 6, width: '100%' }}>
                                        { this.state.photos.length > 0 ?
                                        this.state.photos.map((photo, index) => {
                                        return (
                                            <div
                                                id={'point-'+this.props.point.id+'-photo-'+(index + 1)}
                                                onClick={() => { this.showPhoto(photo.url, index, photo.id); }}
                                                style={{
                                                    backgroundImage: 'url('+photo.url+')',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center, center',
                                                    backgroundRepeat: 'no-repeat',
                                                    cursor: 'pointer',
                                                    width: 85,
                                                    height: 85,
                                                    float: 'left',
                                                    position: 'relative',
                                                    marginRight: 6,
                                                    marginBottom: 6,
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    borderColor: '#e0e0e0',
                                                    borderRadius: 5
                                                }}
                                                >
                                                { this.state.photo_data && this.state.photo_data['drawing-'+photo.id] ?
                                                <div
                                                    style={{
                                                        backgroundImage: 'url('+this.state.photo_data['drawing-'+photo.id]+')',
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center, center',
                                                        backgroundRepeat: 'no-repeat',
                                                        width: 85,
                                                        height: 85,
                                                        /*float: 'left',*/
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0
                                                    }}></div>
                                                :
                                                null }
                                                { !this.state.readonly ? <CloseCircleOutlined
                                                    onClick={(e) => {

                                                        e.stopPropagation();

                                                        this.somethingChanged();

                                                        this.deletePhoto(photo, index);

                                                        return false;
                                                    }}
                                                    style={{ color: 'red', fontSize: 20, position: 'absolute', top: 5, right: 5, zIndex: 9998 }} /> : null }
                                            </div>
                                        );
                                        })
                                        : null }
                                    </div>

                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={24} style={{columnGap: '20px', rowGap: '20px'}}>

                                <Upload.Dragger
                                  disabled={this.state.readonly}
                                  style={{ backgroundColor: '#fff', marginBottom: 8 }}
                                  accept="image/jpeg, image/png"
                                  multiple
                                  listType="picture-card"
                                  beforeUpload={(file) => {
                                    this.somethingChanged();

                                    this.setState((state) => ({
                                      fileList: [ ...state.fileList, file ]
                                    }));

                                    return false;
                                  }}
                                  onRemove={(file) => {

                                      var filelist = this.state.fileList;
                                      var files = [];

                                      filelist.map((record) => {
                                          if(file.uid != record.uid)
                                          {
                                              files.push(record);
                                          }
                                      });

                                      this.setState({ fileList: files });
                                  }}
                                  onPreview={this.handlePreview}
                                  >
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">Klik of sleep foto's hierheen om te uploaden.</p>
                                  <p className="ant-upload-hint">Maximale bestandsgrootte van de upload: 8 MB.</p>
                                </Upload.Dragger>

                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>

                            </Col>
                        </Row>
                        <Row className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={12} style={{ paddingRight: '8px' }}>

                                <Form.Item
                                    label="Onderwerp"
                                    hasFeedback
                                    disabled={this.state.readonly}
                                    >
                                    {getFieldDecorator('subject', {
                                        rules: [{ required: true, message: true }],
                                        initialValue: this.state.point.subject,
                                        onChange: () => { this.somethingChanged(); }
                                    })(
                                    <Input
                                        disabled={this.state.readonly}
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                            <Col className="gutter-row" span={12} style={{ paddingLeft: '8px' }}>

                                <Form.Item
                                    label="Selecteer een verantwoordelijke"
                                    hasFeedback
                                    disabled={this.state.readonly}
                                    >
                                    {getFieldDecorator('assigned_to_delivery_user_id', {
                                        rules: [{ required: true, message: true }],
                                        initialValue: this.state.point.assigned_to_delivery_user_id ? ''+this.state.point.assigned_to_delivery_user_id : null,
                                        onChange: () => { this.somethingChanged(); }
                                    })(
                                    <Select
                                        disabled={this.state.readonly}
                                        showSearch
                                        //allowClear
                                        placeholder="Geef een verantwoordelijke op"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSearch={(value) => {
                                            // alert('Search');
                                            if(value)
                                            {
                                                this.setState({ user: value });
                                            }
                                        }}
                                        onBlur={() => {
                                            var users = this.state.users ? this.state.users : [];
                                            var user = this.state.user;

                                            this.setState({ user: '' });

                                            if(user)
                                            {
                                                users.push(user);

                                                this.setState({ users: users }, () => {
                                                    this.props.form.setFieldsValue({ assigned_to_delivery_user_id: user });
                                                });
                                            }
                                        }}
                                    >
                                        { this.state.user_filters && this.state.user_filters.length && this.state.user_filters.map((record) => {
                                            return <Option value={record}>{record}</Option>
                                        }) }
                                        { this.props.user_filters.map((record, index) => {
                                            return <Option value={''+record.id}>{record.full_name ? record.full_name + ' (' + record.email_address + (record.company_name ? ', '+record.company_name+'' : '') + ')' : record.email_address}</Option>
                                        }) }
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={12} style={{ paddingRight: '8px' }}>

                                <Form.Item
                                    label="Status"
                                    hasFeedback
                                    className="mbn"
                                    disabled={this.state.readonly}
                                    >
                                    {getFieldDecorator('status', {
                                        rules: [{ required: true, message: true }],
                                        initialValue: this.state.point.status ? this.state.point.status : 'open',
                                        onChange: (status) => {
                                            this.somethingChanged();
                                            this.changeStatus(status);
                                        }
                                    })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Selecteer een status"
                                        disabled={this.state.readonly}
                                    >
                                        <Option value={'open'}>Open</Option>
                                        <Option value={'submitted'}>Controleren</Option>
                                        <Option value={'completed'}>Afgehandeld</Option>
                                    </Select>
                                    )}
                                </Form.Item>

                            </Col>
                            <Col className="gutter-row" span={12} style={{ paddingLeft: '8px' }}>

                                <Form.Item
                                    label="Uiterlijk gereed"
                                    disabled={this.state.readonly}
                                    >
                                    {getFieldDecorator('due_date', {
                                        initialValue: this.state.point.due_date ? moment(this.state.point.due_date) : null,
                                    })(
                                    <DatePicker
                                        format={'DD-MM-YYYY'}
                                        onChange={() => { this.somethingChanged(); }}
                                        disabled={this.state.readonly}
                                    />
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>

                        {/*<Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={24}>

                                <Form.Item
                                    label="Tags"
                                    hasFeedback
                                    className="mbn"
                                >
                                    {this.state.tags.map((tag, index) => {
                                        const isLongTag = tag.length > 20;
                                        const tagElem = (
                                            <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
                                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                            </Tag>
                                        );

                                        return isLongTag ? (
                                            <Tooltip title={tag} key={tag}>
                                                {tagElem}
                                            </Tooltip>
                                        ) : (
                                            tagElem
                                        );
                                    })}

                                    {inputVisible && (
                                        getFieldDecorator('tags', {
                                            rules: [{ message: true }]
                                        })(
                                        <Input
                                            ref={this.saveInputRef}
                                            type="text"
                                            size="small"
                                            style={{ width: 78 }}
                                            value={this.state.inputValue}
                                            onChange={(event) => { this.somethingChanged(); this.handleInputChange(event); }}
                                            onBlur={this.handleInputConfirm}
                                            onPressEnter={this.handleInputConfirm}
                                        />
                                        )
                                    )}

                                    {!inputVisible && (
                                        <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                            + Nieuwe tag
                                        </Tag>
                                    )}
                                </Form.Item>

                            </Col>
                        </Row>/**/}

                        { !isNaN(this.state.point_id) && this.state.point_id >= 1 ?
                        <Row gutter={24} className={'mbm'} style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={24}>

                                <h2 style={{marginTop: 24, marginBottom: 12}}>Opmerkingen</h2>

                                <Row gutter={24}>
                                    <Col span={24} style={{ paddingBottom: 24 }}>
                                        {/*<Button type={'new'} style={{ marginTop: 28 }} disabled={!this.state.can_write} onClick={() => { this.addNewComment(this.props.point.id); }}>Opmerking toevoegen</Button>*/}
                                        <Dropdown trigger={['click']} overlay={(
                                            <Menu>
                                              <Menu.Item disabled={!this.state.can_write} key="5" onClick={() => { this.showComment(null); }}>Toevoegen</Menu.Item>
                                              <Menu.Divider></Menu.Divider>
                                              <Menu.Item disabled={this.state.can_delete && this.state.selectedRowKeysComments.length > 0 ? false : true} style={{color: 'red', opacity: (this.state.can_delete && this.state.selectedRowKeysComments.length > 0 ? 1 : 0.33)}} key="4" onClick={() => { this.deleteComments(this.state.point_id); }}>Verwijderen</Menu.Item>
                                            </Menu>
                                        )} disabled={ this.state.can_write && !this.state.readonly ? false : true }>
                                          <Button type="secondary">
                                            Acties <DownOutlined />
                                          </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionComments}
                                            columns={comment_columns}
                                            dataSource={this.state.point.comments}
                                            loading={this.state.loading}
                                            />
                                    </Col>
                                </Row>

                            </Col>
                        </Row> : null }

                        { !isNaN(this.state.point_id) && this.state.point_id >= 1 && !this.state.point_parent_id ?
                        <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col className="gutter-row" span={24}>

                                <h2 style={{marginTop: 24, marginBottom: 12}}>Sub punten</h2>

                                <Row gutter={24}>
                                    <Col span={24} style={{ paddingBottom: 24 }}>
                                        {/*<Button type={'new'} style={{ marginTop: 28 }} disabled={!this.state.can_write} onClick={() => { this.addNewSubPoint(this.props.point.id); }}>Sub punt toevoegen</Button>*/}
                                        <Dropdown trigger={['click']} overlay={(
                                            <Menu>
                                                <Menu.Item disabled={!this.state.can_write} key="5" onClick={() => { this.addNewSubPoint(this.state.point_id); }}>Toevoegen</Menu.Item>
                                                <Menu.Divider></Menu.Divider>
                                                <Menu.Item disabled={this.state.can_delete && this.state.selectedRowKeysPoints.length > 0 ? false : true} style={{color: 'red', opacity: (this.state.can_delete && this.state.selectedRowKeysPoints.length > 0 ? 1 : 0.33)}} key="4" onClick={() => { this.deletePoints(); }}>Verwijderen</Menu.Item>
                                            </Menu>
                                          )} disabled={ this.state.can_write && !this.state.readonly ? false : true }>
                                          <Button type="secondary">
                                            Acties <DownOutlined />
                                          </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <CustomTable
                                            rowKey='id'
                                            size="middle"
                                            rowSelection={rowSelectionPoints}
                                            columns={point_columns}
                                            dataSource={this.state.point.children}
                                            loading={this.state.loading}
                                            />
                                    </Col>
                                </Row>

                            </Col>
                        </Row> : null }

                        <Row gutter={24} className="mbm" style={{columnGap: '20px', rowGap: '20px'}}>
                            <Col span={24} style={{columnGap: '20px', rowGap: '20px'}}>

                                <h2 style={{marginTop: 28}}>Tijdlijn</h2>

                                { this.state.log.length > 0 ?
                                <Timeline style={{marginTop: 24}}>
                                  {
                                      this.state.log.map((object, key) => {
                                          return (<Timeline.Item><RenderHTML HTML={(moment(object.created_at).format('DD/MM/YYYY HH:mm')  + ' uur - ' + object.message)} /></Timeline.Item>);
                                      })
                                  }
                                </Timeline> : null }

                                {
                                    !this.state.log.length ?
                                <p>Er staat nog niets op de tijdlijn.</p>
                                    : null
                                }

                            </Col>
                        </Row>

                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px 10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                                zIndex: 9999
                            }}
                            >
                            <Button onClick={() => {
                                    if(this.props.closeSubPoint)
                                    {
                                        this.props.closeSubPoint();
                                    }
                                    else
                                    {
                                        this.props.closePoint(true);
                                    }
                                }}
                                style={{ marginRight: 8 }}
                                >
                                Annuleren
                            </Button>
                            <Button
                                form={(this.state.point_parent_id ? 'sub_' : '') + 'deliver_point'}
                                disabled={!this.state.can_write || this.state.readonly}
                                type={'new'}
                                key={'submit'}
                                htmlType={'submit'}
                                //onClick={() => { /*this.handleSave((!isNaN(this.state.point_id) ? true : false));*/ }}
                                >
                                Opslaan
                            </Button>
                        </div>

                    </div>}

                    <Modal
                        centered
                        className="drawing"
                        width="800"
                        height="400"
                        footer={null}
                        visible={this.state.show_drawing_modal}
                        // visible={true}
                        forceRender={true}
                        closeIcon={null}
                        maskClosable={false}
                        closable={false}
                        // style={".ant-modal-close { display: 'none' }"}
                        // onCancel={() => { this.setState({ show_drawing_modal: false }); }}
                        >
                        <div id="map" style={{ width: 800, height: 400, zIndex: 1 }}></div>
                        { this.state.map_locked ? <div onClick={() => { if(!this.state.readonly){ this.setState({ map_locked: false }); }}} style={{ width: 800, height: 400, zIndex: 2, position: 'absolute', top: 24, left: 24, backgroundColor: 'rgba(0, 0, 0, 0.25)', textAlign: 'center', verticalAlign: 'middle' }}>
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '190px' }}>
                                { !this.state.readonly ? 'Klik op de kaart om deze te ontgrendelen en een punt te plaatsen of het bestaande punt te verplaatsen.' : 'De kaart is vergrendeld.'}
                            </div>
                        </div> : null }
                        <div style={{ clear: 'both', paddingTop: 10 }}>
                            &nbsp;
                            <div style={{ float: 'right' }}>
                                <Button onClick={() => {
                                    if(this.marker)
                                    {
                                        this.marker.setLatLng(this.state.original_drawing_marker_position);
                                    }

                                    this.setState({
                                        drawing_marker_position: this.state.original_drawing_marker_position,
                                        // drawing_marker_x_y: this.state.original_drawing_marker_x_y,
                                        show_drawing_modal: false
                                    });
                                }}>Annuleren</Button>
                                &nbsp;
                                <Button
                                    type={'new'}
                                    onClick={() => {
                                        this.setState({
                                            original_drawing_marker_position: this.state.drawing_marker_position,
                                            // original_drawing_marker_x_y: this.state.drawing_marker_x_y,
                                            show_drawing_modal: false
                                        });
                                    }}
                                    disabled={this.state.readonly}
                                    >Toepassen</Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        centered
                        className="large-photo"
                        width="800"
                        footer={null}
                        visible={this.state.show_photo_modal}
                        onCancel={() => { this.setState({ show_photo_modal: false }); }}
                        >
                        <div onClick={() => { this.setState({ show_photo_modal: false }); }} style={{ width: '100%', textAlign: 'center', position: 'relative' }}>
                            <img src={this.state.photo_url} style={{ maxWidth: '600px', maxHeight: '600px' }} />
                            { this.state.photo_data && this.state.photo_data['drawing-'+this.state.photo_id] ?
                            <div
                                style={{
                                    backgroundImage: 'url('+this.state.photo_data['drawing-'+this.state.photo_id]+')',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center, center',
                                    backgroundRepeat: 'no-repeat',
                                    /*width: 85,
                                    height: 85,
                                    float: 'left',*/
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                }}></div>
                            :
                            null }
                        </div>
                    </Modal>

                </Form>

                { this.state.comment_modal ? <Drawer
                    title={'Opmerking'}
                    placement={'right'}
                    className="comment-details"
                    width={parseInt(window.width / 3)}
                    headerStyle={{ position: 'absolute', width: '100%', zIndex: 9999 }}
                    bodyStyle={{ paddingTop: 70 }}
                    leaflet={false}
                    visible={this.state.comment_modal}
                    onClose={() => { this.closeComment(false); }}
                    >
                    <div>
                        <Form
                            id="comment"
                            onSubmit={(event) => { event.preventDefault(); this.addComment(this.state.point_id); }}
                            style={{ width: '100%', minWidth: '660px' }}
                            >
                            { !this.state.comment_id ?
                            <Form.Item
                                label="Plaats een opmerking"
                                hasFeedback
                                >
                                {getFieldDecorator('comment', {
                                    rules: [{ message: true }]
                                })(
                                <TextArea
                                    rows={4}
                                    disabled={!this.state.can_write}
                                    />
                                )}
                            </Form.Item>
                            : this.state.comment }

                            <div
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e9e9e9',
                                    padding: '10px 16px',
                                    background: '#fff',
                                    textAlign: 'right',
                                    zIndex: 9999
                                }}
                                >
                                { !this.state.comment_id ?<div>
                                    <Button onClick={() => {
                                            this.closeComment(false);
                                        }}
                                        style={{ marginRight: 8 }}
                                        >
                                        Annuleren
                                    </Button>
                                    <Button
                                        form={'comment'}
                                        disabled={!this.state.can_write || this.state.readonly}
                                        type={'new'}
                                        key={'submit'}
                                        htmlType={'submit'}
                                        >
                                        Opslaan
                                    </Button>
                                </div>
                                :
                                <Button onClick={() => {
                                        this.closeComment(false);
                                    }}
                                    style={{ marginRight: 8 }}
                                    >
                                    Sluiten
                                </Button>

                                }
                            </div>
                        </Form>

                    </div>
                </Drawer> : null }

                { this.state.sub_point_modal ? <Drawer
                    title={this.state.modal_title}
                    placement={'right'}
                    className="point-details"
                    width={parseInt(window.width / 3)}
                    headerStyle={{ position: 'absolute', width: '100%', zIndex: 9999 }}
                    bodyStyle={{ paddingTop: 70 }}
                    leaflet={false}
                    visible={this.state.sub_point_modal}
                    onClose={() => { this.closeSubPoint(); }}
                    >
                    <DeliverPointChild
                        //{...this.props}
                        //project_id={this.props.match.params.id}
                        childRef={(ref) => { this.point_ref = ref; }}
                        project_id={this.props.project_id}
                        sub_point_id={this.state.sub_point_id}
                        sub_point_parent_id={this.state.sub_point_parent_id}
                        subpoint={true}
                        closeSubPoint={this.closeSubPoint}
                        somethingChangedSub={this.somethingChangedSub}
                        //{...this.props}
                        />
                </Drawer> : null }

            </div>
        );
    }
}

const DeliverPointModalForm = Form.create({ name: 'point_details' })(DeliverPointModal);

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching : state.deliver.isFetching,
        isError : state.deliver.isError,
        point : state.deliver.point,
        photos : state.deliver.photos,
        log : state.deliver.log,
        users : state.deliver.users,
        comments : state.deliver.comments,
        delivery_filters : state.deliver.delivery_filters,
        form_filters : state.deliver.form_filters,
        drawings : state.deliver.drawings,
        delivery : state.deliver.delivery,
        user_filters : state.deliver.user_filters,
        points_deliveries : state.deliver.points_deliveries
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPointAction : bindActionCreators(getPointAction, dispatch),
        updatePointAction : bindActionCreators(updatePointAction, dispatch),
        resetPointAction : bindActionCreators(resetPointAction, dispatch),
        getUsersAction : bindActionCreators(getUsersAction, dispatch),
        addPointAction : bindActionCreators(addPointAction, dispatch),
        deletePointsAction : bindActionCreators(deletePointsAction, dispatch),
        getDeliveryFilterAction : bindActionCreators(getDeliveryFilterAction, dispatch),
        getFormFilterAction : bindActionCreators(getFormFilterAction, dispatch),
        getLocationFilterAction : bindActionCreators(getLocationFilterAction, dispatch),
        getDrawingsAction : bindActionCreators(getDrawingsAction, dispatch),
        getDeliveryAction : bindActionCreators(getDeliveryAction, dispatch),
        addDeliveryPointCommentAction : bindActionCreators(addDeliveryPointCommentAction, dispatch),
        deleteDeliveryPointCommentsAction : bindActionCreators(deleteDeliveryPointCommentsAction, dispatch),
        // getPointDeliveriesAction : bindActionCreators(getPointDeliveriesAction, dispatch),
        HaveAbility : bindActionCreators(HaveAbility, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverPointModalForm);
